/**
 * Amasty RMA compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import PropTypes from 'prop-types';
import { createRef, PureComponent } from 'react';
import { connect } from 'react-redux';

import { hideActiveOverlay, hideActivePopup } from 'Store/Overlay/Overlay.action';
import { showPopup } from 'Store/Popup/Popup.action';
import { fetchMutation } from 'Util/Request';

import AmastyChatMutation from '../../query/AmastyChat.mutation';
import { ID_POPUP_INFO } from '../../route/AmastyNewReturn/AmastyNewReturn.config';
import { AmRmaRequestMessagesType } from '../../type/AmastyRMA.type';
import { sendFiles } from '../../util/FileUploader';
import AmastyChatComponent from './AmastyChat.component';
import {
    ATTRIBUTE_FILE_HASH, ATTRIBUTE_MESSAGE_ID, ID_POPUP_CONFIRM
} from './AmastyChat.config';

/** @namespace Scandiweb/AmastyRma/Component/AmastyChat/Container/mapStateToProps */
export const mapStateToProps = (_state) => ({});

/** @namespace Scandiweb/AmastyRma/Component/AmastyChat/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    showMyPopUp: (popupKey, payload) => dispatch(showPopup(popupKey, payload)),
    hideActiveOverlay: () => dispatch(hideActiveOverlay()),
    hideActivePopup: () => dispatch(hideActivePopup())
});

/** @namespace Scandiweb/AmastyRma/Component/AmastyChat/Container */
export class AmastyChatContainer extends PureComponent {
  static propTypes = {
      showMyPopUp: PropTypes.func.isRequired,
      hideActivePopup: PropTypes.func.isRequired,
      messages: AmRmaRequestMessagesType.isRequired,
      requestHash: PropTypes.string.isRequired
  };

  containerFunctions = {
      uploadFiles: this.uploadFiles.bind(this),
      submitMessage: this.submitMessage.bind(this),
      updateNewMessageText: this.updateNewMessageText.bind(this),
      confirmMessageDeletion: this.confirmMessageDeletion.bind(this),
      deleteMessage: this.deleteMessage.bind(this),
      deleteUploadedFile: this.deleteUploadedFile.bind(this),
      scrollChatToBottom: this.scrollChatToBottom.bind(this),
      toggleSubmitButton: this.toggleSubmitButton.bind(this),
      closePopUp: this.closePopUp.bind(this)
  };

  componentDidMount() {
      this.loadState();
  }

  __construct(props) {
      super.__construct(props);
      this.state = {
          isLoading: true,
          messages: {},
          newMessageText: '',
          newMessageFiles: [],
          isSubmitButtonDisabled: true
      };
  }

  async loadState() {
      const {
          messages
      } = this.props;

      await this.setState({
          messages,
          chatBodyElement: createRef(),
          newMessageInput: createRef(),
          isLoading: false
      });
      this.scrollChatToBottom();
  }

  async confirmMessageDeletion(e) {
      if (e.type === 'keyup') {
          if (e.code !== 'Enter' || e.code !== 'Space') {
              return;
          }
      }
      const { showMyPopUp } = this.props;
      this.setState({
          deletedMessageId: parseInt(e.target.getAttribute(ATTRIBUTE_MESSAGE_ID), 10)
      });
      this.setState({
          popupMessage: __('Are you sure you want to delete this message?')
      });
      showMyPopUp(ID_POPUP_CONFIRM);
  }

  async deleteMessage(e) {
      if (e.type === 'keyup') {
          if (e.code !== 'Enter' || e.code !== 'Space') {
              return;
          }
      }

      this.closePopUp();

      const {
          showMyPopUp,
          requestHash
      } = this.props;

      const {
          deletedMessageId,
          messages
      } = this.state;

      const { deleteAmRmaMessage: { error } } = await fetchMutation(
          AmastyChatMutation.deleteMessage(requestHash, deletedMessageId)
      );

      if (error !== false) {
          this.setState({
              popupMessage: __('An error occurred. Please try again later.')
          });
          showMyPopUp(ID_POPUP_INFO);
          return;
      }
      const currentMessages = messages.filter(
          ({ message_id }) => message_id !== deletedMessageId
      );

      this.setState({
          messages: currentMessages
      });
  }

  async uploadFiles(e) {
      const {
          showMyPopUp
      } = this.props;

      e.preventDefault();
      this.setState({
          isSubmitButtonDisabled: true
      });
      try {
          const newFiles = await sendFiles(Array.from(e.target.files));
          const { newMessageFiles: currentFiles } = this.state;
          this.setState({
              newMessageFiles: currentFiles.concat(newFiles)
          });
      } catch (error) {
          this.setState({
              popupMessage: __('Error while uploading file. File might be too large, please try again!')
          });
          showMyPopUp(ID_POPUP_INFO);
      }

      this.toggleSubmitButton();
  }

  async deleteUploadedFile(e) {
      if (e.type === 'keyup') {
          if (e.code !== 'Enter' || e.code !== 'Space') {
              return;
          }
      }

      const {
          showMyPopUp
      } = this.props;

      const deletedFileHash = e.target.getAttribute(ATTRIBUTE_FILE_HASH);

      this.setState({
          isDeleteButtonDisabled: true,
          isSubmitButtonDisabled: true
      });
      const { error } = await fetchMutation(AmastyChatMutation.deleteNewMessageFile(deletedFileHash));
      if (error) {
          this.setState({
              popupMessage: __('An error occurred, please try again.')
          });
          showMyPopUp(ID_POPUP_INFO);
      } else {
          const { newMessageFiles } = this.state;
          const currentFiles = newMessageFiles.filter(({ filehash }) => filehash !== deletedFileHash);

          this.setState({
              newMessageFiles: currentFiles
          });
          this.toggleSubmitButton();
      }
      this.setState({
          isDeleteButtonDisabled: false
      });
  }

  async submitMessage() {
      const {
          newMessageFiles,
          newMessageInput
      } = this.state;

      const {
          requestHash
      } = this.props;

      this.setState({
          isSubmitButtonDisabled: true
      });

      const input = {
          hash: requestHash,
          message: newMessageInput.current.value,
          files: newMessageFiles
      };
      const newMessage = await fetchMutation(AmastyChatMutation.sendNewMessage(input));
      const { messages } = this.state;

      const allMessages = [...messages, newMessage.sendAmRmaMessage];
      this.setState({
          messages: allMessages,
          newMessageFiles: []
      });
      newMessageInput.current.value = '';
      this.toggleSubmitButton();
      this.scrollChatToBottom();
  }

  async updateNewMessageText(e) {
      await this.setState({
          newMessageText: e.target.value
      });
      this.toggleSubmitButton();
  }

  toggleSubmitButton() {
      const { newMessageFiles, newMessageText } = this.state;
      if (newMessageText.trim().length === 0 && newMessageFiles.length === 0) {
          this.setState({
              isSubmitButtonDisabled: true
          });

          return;
      }

      this.setState({
          isSubmitButtonDisabled: false
      });
  }

  scrollChatToBottom() {
      const { chatBodyElement } = this.state;
      chatBodyElement.current.scrollTop = chatBodyElement.current.scrollHeight;
  }

  async closePopUp() {
      const { hideActivePopup } = this.props;
      hideActivePopup();
  }

  containerProps() {
      const {
          isLoading,
          messages,
          isSubmitButtonDisabled,
          newMessageFiles,
          chatBodyElement,
          newMessageInput,
          popupMessage,
          isDeleteButtonDisabled
      } = this.state;

      return {
          isLoading,
          messages,
          isSubmitButtonDisabled,
          newMessageFiles,
          chatBodyElement,
          newMessageInput,
          popupMessage,
          isDeleteButtonDisabled
      };
  }

  render() {
      return (
      <AmastyChatComponent
        { ...this.containerFunctions }
        { ...this.containerProps() }
      />
      );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AmastyChatContainer);
