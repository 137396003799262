/**
 * Amasty RMA compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { Field } from 'Util/Query';

/** @namespace Scandiweb/AmastyRma/Query/AmastyRmaSettings/Query */
export class AmastyRmaSettingsQuery {
    getAllReasonsField() {
        return new Field('allReasons')
            .addFieldList(this.getAllReasonsFields());
    }

    getAllResolutionsField() {
        return new Field('allResolutions')
            .addFieldList(this.getAllResolutionsFields());
    }

    getAllConditionsField() {
        return new Field('allConditions')
            .addFieldList(this.getAllConditionsFields());
    }

    getAllReasonsFields() {
        return [
            'reason_id',
            'label',
            'payer'
        ];
    }

    getAllResolutionsFields() {
        return [
            'resolution_id',
            'label',
            'status'
        ];
    }

    getAllConditionsFields() {
        return [
            'condition_id',
            'label',
            'payer'
        ];
    }
}

export default new AmastyRmaSettingsQuery();
