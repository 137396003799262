import PropTypes from 'prop-types';

import {
    Form as SourceForm
} from 'SourceComponent/Form/Form.component';

/** @namespace Frontend/Component/Form/Component */
export class Form extends SourceForm {
    static propTypes = {
        ...SourceForm.propTypes,
        idForm: PropTypes.string
    };

    static defaultProps = {
        ...SourceForm.defaultProps,
        idForm: ''
    };

    render() {
        const {
            validationResponse,
            children,
            setRef,
            attr,
            events,
            mix,
            idForm
        } = this.props;

        return (
            <>
                { this.renderLabel() }
                <form
                  // eslint-disable-next-line @scandipwa/scandipwa-guidelines/jsx-no-props-destruction
                  { ...attr }
                  // eslint-disable-next-line @scandipwa/scandipwa-guidelines/jsx-no-props-destruction
                  { ...events }
                  ref={ (elem) => setRef(elem) }
                  id={ idForm }
                  block="Form"
                  mix={ mix }
                  mods={ {
                      isValid: validationResponse === true,
                      hasError: validationResponse !== true && Object.keys(validationResponse || {}).length !== 0
                  } }
                  noValidate
                >
                    { children }
                </form>
                { this.renderErrorMessages() }
                { this.renderSubLabel() }
            </>
        );
    }
}

export default Form;
