/**
 * Amasty RMA compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { Field } from 'Util/Query';

/** @namespace Scandiweb/AmastyRma/Query/AmastyChat/Query */
export class AmastyChatQuery {
    getRequestMessagesField(hash) {
        return new Field('getAmRmaRequestMessages')
            .addArgument('hash', 'String!', hash)
            .addFieldList(this.getRequestMessageFields());
    }

    getFilesField() {
        return new Field('files')
            .addFieldList(this.getFilesFields());
    }

    getRequestMessageFields() {
        return [
            'message_id',
            'username',
            'created',
            'is_manager',
            'is_system',
            'message',
            this.getFilesField()
        ];
    }

    getFilesFields() {
        return [
            'filename',
            'filepath'
        ];
    }
}

export default new AmastyChatQuery();
