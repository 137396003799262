import {
    MyAccountQuery as SourceMyAccountQuery
} from 'SourceQuery/MyAccount.query';
import { Field } from 'Util/Query';

/** @namespace Frontend/Query/MyAccount/Query */
export class MyAccountQuery extends SourceMyAccountQuery {
    _getCustomerFields() {
        return [
            'created_at',
            'confirmation_required',
            'group_id',
            'prefix',
            'firstname',
            'middlename',
            'lastname',
            'suffix',
            'email',
            'default_billing',
            'default_shipping',
            'dob',
            'taxvat',
            'id',
            'is_subscribed',
            'telefono',
            'gender',
            'date_of_birth',
            this._getConsentsFields(),
            this._getFidelityFields(),
            this._getAddressesField()
        ];
    }

    _getConsentsFields() {
        return new Field('consents')
            .addFieldList(this._getConsentFields());
    }

    _getConsentFields() {
        return [
            'consent_id',
            'consent_value'
        ];
    }

    _getFidelityFields() {
        return new Field('customAttributes')
            .addFieldList(this._getFidelityField());
    }

    _getFidelityField() {
        return [
            'external_code',
            'card_fidelity_barcode',
            'card_fidelity_activation_date',
            'card_fidelity_expiration_date',
            'card_fidelity_earned_points'
        ];
    }
}

export default new MyAccountQuery();
