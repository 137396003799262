/**
 * Amasty RMA compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { Field } from 'Util/Query';

import AmastyChatQuery from './AmastyChat.query';

/** @namespace Scandiweb/AmastyRma/Query/AmastyChat/Mutation */
export class AmastyChatMutation {
    sendNewMessage(input) {
        return new Field('sendAmRmaMessage')
            .addArgument('input', 'SendAmRmaMessageInput', input)
            .addFieldList(AmastyChatQuery.getRequestMessageFields());
    }

    deleteMessage(requestHash, messageId) {
        return new Field('deleteAmRmaMessage')
            .addArgument('input', 'DeleteAmRmaMessageInput', {
                hash: requestHash,
                message_id: messageId
            })
            .addFieldList(this.getErrorFields());
    }

    deleteNewMessageFile(filehash) {
        return new Field('deleteAmRmaMessageFile')
            .addArgument('filehash', 'String!', filehash)
            .addFieldList(this.getErrorFields());
    }

    getErrorFields() {
        return [
            'error',
            'message'
        ];
    }
}

export default new AmastyChatMutation();
