/**
 * Amasty RMA compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { appendTokenToHeaders } from 'Util/Request';

/** @namespace Scandiweb/AmastyRma/Util/FileUploader/toBase64 */
export const toBase64 = (file) => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
});

/** @namespace Scandiweb/AmastyRma/Util/FileUploader/sendFiles */
export const sendFiles = async (files) => {
    const filesContents = await Promise.all(files.map(async (file) => {
        const { name } = file;
        const fileName = (/(.*)\.([^.]*)$/).exec(name);
        return {
            original_file_name: fileName[1],
            extension: fileName[2],
            base64_encoded_data: await toBase64(file)
        };
    }));

    const response = await fetch(
        '/rest/V1/amasty_rma/uploadFiles',
        {
            method: 'POST',
            headers: appendTokenToHeaders({
                'Content-Type': 'application/json'
            }),
            body: JSON.stringify({
                filesContents
            })
        }
    );

    const STATUS_OK = 200;
    if (response.status === STATUS_OK) {
        const data = await response.json();
        return data.map((file) => {
            const { filehash, original_file_name } = file;
            return {
                filehash,
                filename: original_file_name
            };
        });
    }

    throw new Error('Upload failed. Either the file is too large or a server error');
};
