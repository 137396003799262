/**
 * Amasty RMA compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { Field } from 'Util/Query';

/** @namespace Scandiweb/AmastyRma/Query/AmastyViewReturn/Mutation */
export class AmastyViewReturnMutation {
    cancelRMA(requestId) {
        return new Field('closeAmRmaCustomerReturnRequest')
            .addArgument('id', 'Int!', requestId)
            .addFieldList(this.getErrorFields());
    }

    rateReturn(input) {
        return new Field('rateAmRmaReturnRequest')
            .addArgument('input', 'RateAmRmaReturnRequest', input)
            .addFieldList(this.getErrorFields());
    }

    getErrorFields() {
        return [
            'error',
            'message'
        ];
    }
}

export default new AmastyViewReturnMutation();
