import PropTypes from 'prop-types';

import {
    FormContainer as SourceFormContainer
} from 'SourceComponent/Form/Form.container';

/** @namespace Frontend/Component/Form/Container */
export class FormContainer extends SourceFormContainer {
    static propTypes = {
        ...SourceFormContainer.propTypes,
        idForm: PropTypes.string
    };

    static defaultProps = {
        ...SourceFormContainer.defaultProps,
        idForm: ''
    };

    containerProps() {
        const { idForm } = this.props;

        return {
            ...super.containerProps(),
            idForm
        };
    }
}

export default FormContainer;
