/**
 * Amasty RMA compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { Field } from 'Util/Query';

/** @namespace Scandiweb/AmastyRma/Query/AmastyNewReturn/Mutation */
export class AmastyNewReturnMutation {
    saveNewReturn(input) {
        return new Field('placeAmRmaCustomerReturnRequest')
            .addArgument('input', 'PlaceAmRmaReturnRequestInput', input)
            .addField('request_id');
    }
}

export default new AmastyNewReturnMutation();
